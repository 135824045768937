import React from 'react';

import { EverBrightLogo } from 'shared/components/icons';
import { Text } from 'shared/components/Text/Text';
import { HeaderStyled } from './Header.styles';

type Props = {
  title: string;
  titleBold: string;
  showLogo?: boolean;
};
export function Header({ title, titleBold, showLogo = true }: Props) {
  return (
    <HeaderStyled $showLogo={showLogo}>
      {showLogo ? <EverBrightLogo /> : null}
      <Text as="h1">
        {title} <b>{titleBold}</b>
      </Text>
    </HeaderStyled>
  );
}

export default Header;
