import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { pages } from 'pages';
import { Header } from 'modules/auth/components/Header/Header';
import { Button } from 'shared/components/Button/Button';
import { AuthBox, AuthWrapper } from '../Registration/Registration.styles';
import {
  BodyText,
  RegistrationEmailFormStyled,
} from '../../components/RegistrationEmailForm/RegistrationEmailForm.styles';

export function TooManyAttempts() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClick = () => {
    navigate(`/${pages.LOGIN}`);
  };
  return (
    <AuthWrapper>
      <AuthBox>
        <Header
          title={t('tooManyAttempts.tooMany')}
          titleBold={t('tooManyAttempts.attempts')}
          showLogo
        />
        <RegistrationEmailFormStyled>
          <div>
            <Trans>
              <BodyText as="p">{t('tooManyAttempts.unableToVerify')}</BodyText>

              <BodyText $margin="18px 0 0" as="p">
                {t('tooManyAttempts.lockingYourAccount')}
              </BodyText>
            </Trans>
          </div>

          <Button
            margin="24px 0 0"
            onClick={handleClick}
            label={t('tooManyAttempts.returnToLogin')}
            isFullWidth
          />
        </RegistrationEmailFormStyled>
      </AuthBox>
    </AuthWrapper>
  );
}

export default TooManyAttempts;
