import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { ContractType, DateRangeZoomLevel, SystemType } from 'api/types';
import { ArrowUpRightFromSquare } from 'shared/components/icons';
import { getEnergySummary, getPowerFlow, getSiteTimezone } from 'api/system';
import {
  addDays,
  startOfDay,
  startOfMonth,
  startOfToday,
  startOfWeek,
  startOfYear,
} from 'date-fns';
import { DateRange } from 'api/system/utils/createDateRange';
import { LINKS } from 'shared/links';
import { useUser } from 'hooks/useUser';
import { useNavigate } from 'react-router';
import { getSystemWithMonitoring } from 'modules/system/utils/hasMonitoring';
import { pages } from 'pages';
import { Header } from 'shared/components/Header/Header';
import { ChartWrapper } from 'modules/system/components/Charts/Chart.styles';
import {
  SideCardInfo,
  SideCardLabel,
  SystemCard,
  SystemChartHeader,
  SystemPageBodyWrapper,
  SystemPageWrapper,
  SystemSideCards,
} from './SystemPage.styles';
import { DateSelector } from '../../components/DateSelector/DateSelector';
import { TimescaleControl } from '../../components/TimescaleControl/TimescaleControl';
import { MonitoringSnapshot } from '../../components/MonitoringSnapshot/MonitoringSnapshot';
import { getZoomLevelFromLocalTimescale } from '../../utils/getZoomLevelFromLocalTimescale';
import { DayChart } from '../../components/Charts/DayChart/DayChart';
import { EnergyChart } from '../../components/Charts/EnergyChart/EnergyChart';
import { WeekChartDataProvider } from '../../utils/ChartDataProviders/WeekChartDataProvider';
import { MonthChartDataProvider } from '../../utils/ChartDataProviders/MonthChartDataProvider';
import { YearChartDataProvider } from '../../utils/ChartDataProviders/YearChartDataProvider';

export function SystemPage() {
  const { selectedAccount } = useUser();
  const { t } = useTranslation();
  const navigate = useNavigate();

  // set local timescale labels
  const localTimescales = {
    day: t('datePicker.day'),
    week: t('datePicker.week'),
    month: t('datePicker.month'),
    year: t('datePicker.year'),
  };

  const [system, setSystem] = useState<SystemType>();
  const [inServiceDate, setInServiceDate] = useState<Date>();
  const [selectedTimescale, setSelectedTimescale] = useState(localTimescales.day);
  const [dateRange, setDateRange] = useState<DateRange | null>(null);
  const [dateRanges, setDateRanges] = useState<Partial<Record<DateRangeZoomLevel, DateRange>>>({});

  // select the first system with monitoring to display
  useEffect(() => {
    const agreementWithMonitoring = getSystemWithMonitoring(selectedAccount?.agreements);
    // if no systems have monitoring go to hardware details page
    if (selectedAccount && !agreementWithMonitoring) {
      navigate(pages.HARDWARE_INFO, { state: { system: selectedAccount.agreements[0]?.system } });
    }
    if (agreementWithMonitoring?.in_service_date) {
      setInServiceDate(startOfDay(agreementWithMonitoring.in_service_date));
    }
    setSystem(agreementWithMonitoring?.system);
  }, [selectedAccount, navigate]);

  // get system metadata
  const {
    data: systemMetadata,
    isError: systemMetadataError,
    isLoading: systemMetadataIsLoading,
  } = useQuery('siteTimezone', () => getSiteTimezone(system?.id || '').then((res) => res.data), {
    enabled: Boolean(system),
    refetchOnWindowFocus: false,
  });

  // get energy summary data
  const { data: energySummary } = useQuery(
    ['solarEnergySummary', system?.id],
    () => getEnergySummary(system?.id || '').then((res) => res.data),
    {
      enabled: Boolean(system),
      refetchOnWindowFocus: false,
    },
  );

  // get power flow data
  const { data: powerFlowData } = useQuery(
    ['powerFlow', system?.id],
    () => getPowerFlow(system?.id || '').then((res) => res.data),
    {
      enabled: Boolean(system),
      refetchOnWindowFocus: false,
      refetchInterval: 1000 * 10, // refetch every 10 seconds
    },
  );

  const today = startOfToday();

  // set initial dateRange information with today on system metadata update
  useEffect(() => {
    if (systemMetadata) {
      // sets the start of a daily date range to the start of the day in local time
      const newDateRange: DateRange = {
        startDate: today,
        endDate: addDays(today, 1),
        systemTimezone: systemMetadata.timezone,
        zoomLevel: DateRangeZoomLevel.DAY,
      };

      setDateRange(newDateRange);
      setDateRanges({
        ...dateRanges,
        ...{
          [DateRangeZoomLevel.DAY]: newDateRange!,
        },
      });
    }
    // disabling because we only want this to run when systemMetadata changes,
    // and the others aren't likely to change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemMetadata]);

  const handleDateChange = (date: Date) => {
    if (!dateRange || !systemMetadata) {
      return;
    }
    const newDateRange = new DateRange(date, dateRange.zoomLevel, systemMetadata.timezone);

    setDateRange(newDateRange);
    setDateRanges({ ...dateRanges, ...{ [dateRange.zoomLevel]: newDateRange } });
  };

  const handleTimescaleChange = (timescale: string) => {
    setSelectedTimescale(timescale);
    const zoomLevel = getZoomLevelFromLocalTimescale(timescale, localTimescales);
    const existingDateRange = dateRanges[zoomLevel];
    if (existingDateRange) {
      setDateRange(existingDateRange);
    } else {
      let startDate = zoomLevel === DateRangeZoomLevel.YEAR ? startOfMonth(today) : today;
      switch (zoomLevel) {
        case DateRangeZoomLevel.WEEK:
          startDate = startOfWeek(startDate);
          break;
        case DateRangeZoomLevel.MONTH:
          startDate = startOfMonth(startDate);
          break;
        case DateRangeZoomLevel.YEAR:
          startDate = startOfYear(startDate);
          break;
        default:
          break;
      }
      const newDateRange = new DateRange(
        startDate,
        zoomLevel,
        Intl.DateTimeFormat().resolvedOptions().timeZone,
      );
      setDateRange(newDateRange);
      setDateRanges({
        ...dateRanges,
        ...{ [zoomLevel]: newDateRange },
      });
    }
  };

  const showOmnidianCard =
    selectedAccount?.agreements.every(
      (agreement) => agreement.contract_type === ContractType.PPA,
    ) ||
    selectedAccount?.agreements.every((agreement) => agreement.contract_type === ContractType.LSE);

  return (
    <SystemPageWrapper>
      <Header pageName="SYSTEM" title={t('system.title')} />

      <MonitoringSnapshot energySummary={energySummary || null} powerFlow={powerFlowData || null} />
      {system && (
        <SystemPageBodyWrapper>
          {!systemMetadataIsLoading && !systemMetadataError && dateRange && inServiceDate && (
            <ChartWrapper>
              <SystemChartHeader>
                <DateSelector
                  timescaleOptions={localTimescales}
                  timescale={selectedTimescale}
                  currentDate={dateRange.startDate}
                  onDateChange={handleDateChange}
                  dataRange={{ oldest: dateRange.startDate, newest: dateRange.endDate }}
                  inServiceDate={inServiceDate}
                />
                <TimescaleControl
                  timescaleOptions={localTimescales}
                  activeTimescale={selectedTimescale}
                  handleSetTimescale={handleTimescaleChange}
                />
              </SystemChartHeader>
              {selectedTimescale === localTimescales.day && (
                <DayChart dateRange={dateRange} systemId={system.id} />
              )}
              {selectedTimescale === localTimescales.week && (
                <EnergyChart
                  dataProvider={new WeekChartDataProvider()}
                  dateRange={dateRange}
                  systemId={system.id}
                />
              )}
              {selectedTimescale === localTimescales.month && (
                <EnergyChart
                  dataProvider={new MonthChartDataProvider(t)}
                  dateRange={dateRange}
                  systemId={system.id}
                />
              )}
              {selectedTimescale === localTimescales.year && (
                <EnergyChart
                  dataProvider={new YearChartDataProvider()}
                  dateRange={dateRange}
                  systemId={system.id}
                />
              )}
            </ChartWrapper>
          )}
          <SystemSideCards
            $isChartPresent={Boolean(
              !systemMetadataIsLoading && !systemMetadataError && dateRange && inServiceDate,
            )}
          >
            {showOmnidianCard && (
              <SystemCard
                title={t('system.systemService.title')}
                url={LINKS.EVERBRIGHT_OMNIDIAN_LOGIN}
                actionText={t('status.visitWebsite')}
                actionIcon={{ element: <ArrowUpRightFromSquare /> }}
                hasHeaderIcons={false}
              >
                <Trans>
                  <p>{t('system.systemService.copy')}</p>
                </Trans>
              </SystemCard>
            )}
            <SystemCard
              title={t('system.hardwareInformation.title')}
              route={pages.HARDWARE_INFO}
              navState={{ system }}
              actionText={t('system.hardwareInformation.viewDetails')}
              hasHeaderIcons={false}
            >
              <SideCardLabel>{t('system.hardwareInformation.inverters')}</SideCardLabel>
              <SideCardInfo>{system.inverters}</SideCardInfo>
              <SideCardLabel>{t('system.hardwareInformation.capacity')}</SideCardLabel>
              <SideCardInfo>{parseFloat(String(system.capacity))} kW</SideCardInfo>
              <SideCardLabel>
                {t('system.hardwareInformation.batteryStorageCapacity')}
              </SideCardLabel>
              <SideCardInfo>{system.total_battery_capacity_kwh} kWh</SideCardInfo>
            </SystemCard>
          </SystemSideCards>
        </SystemPageBodyWrapper>
      )}
    </SystemPageWrapper>
  );
}

export default SystemPage;
