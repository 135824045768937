import { AuthBox, AuthWrapper, InnerContainer } from 'modules/auth/Auth.styles';
import styled from 'styled-components';

export const ResetPasswordInnerContainer = styled(InnerContainer)`
  ${({ theme }) => `
        h3, h2 {
            display: inline;
        }
        p {
            margin: 24px 0 0;
            font-weight: 300;
            color: ${theme.colors.textSecondaryGrey90};
            text-align: left;
        }
    `}
`;

export const ResetAuthWrapper = styled(AuthWrapper)`
  ${({ theme }) => `
            @media screen and (max-width: ${theme.breakpoints.small}) {
            
            ${AuthBox} {
                ${ResetPasswordInnerContainer} {
                    p {
                        margin-top: 18px;
                    }
                    > div {
                        margin: 18px 0 22px;
                    }
                }
            }
        }
    `}
`;
