import styled from 'styled-components';

export const InnerContainer = styled.div`
  min-width: 260px;
  max-width: 340px;
  margin: 0 auto;
  text-align: center;
`;

export const LoginFooter = styled.p`
  ${({ theme }) => `
    margin: 32px 0 32px 0;
    text-align: center;
    font-size: 16px;
    font-weight: 200;
    color: ${theme.colors.textSecondarySubtleGrey80};
    a {
      text-decoration: none;
      color: ${theme.colors.textSystemInfoSecondary2100};
    }
  `}
`;

export const AuthBox = styled.div`
  ${({ theme }) => `
    display: flex-column;
    width: 500px;
    margin: 32px auto 0 auto;
    background: ${theme.colors.cardElevationMidWhite};
    padding: 48px;
    border-radius: 24px;
    border: 1px solid ${theme.colors.strokeSecondaryGrey10};
    box-shadow: ${theme.elevations.high};
    color: ${theme.colors.textPrimarySecondary1100};

    svg {
      margin-left: 50%;
      transform: translateX(-50%);
      width: 60px;
      height: 60px;
    }
    h3, h2 {
      font-size: 34px;
      line-height: 44px;
      margin: 0;
    }
    h3 {
      font-weight: 100;
    }
    h2 {
      font-weight: 700;
      margin-bottom: 32px;
    }
    
    a {
      text-decoration: none;
      font-weight: 600;
      }
      
      button {
        font-weight: 600;
    }


  `}
`;

export const AuthWrapper = styled.div`
  ${({ theme }) => `
    height: 100%;
    display: flex;
    flex-direction: column;
    // layout is setting this for the actual page, but this is nice to have for storybook
    background: ${theme.colors.backgroundSecondaryGrey05};

    @media screen and (max-width: ${theme.breakpoints.large}) {
        ${AuthBox} {
            width: 460px;
            margin-top: 0;
            padding: 32px;
          }
        p {
          margin-top: 24px;
        }
      }

    @media screen and (max-width: ${theme.breakpoints.medium}) {
        ${AuthBox} {
            width: 410px;
            margin-top: 84px;
            padding: 24px;
            border-radius: 16px;
            svg {
              height: 48px;
              width: 48px;
              }
            ${InnerContainer} {
              max-width: 310px;
            }
            h2, h3 {
              font-size: 24px;
              line-height: 36px;
            }
            h3 {
              font-weight: 100;
            }
            h2 {
              font-weight: 600;
              margin-bottom: 24px;
            }

            label {
              font-weight: 500;
            }
          }
        p {
          margin: 18px auto 68px;
          max-width: 380px;
          text-align: left;
        }
    }

    @media screen and (max-width: ${theme.breakpoints.small}) {
        ${AuthBox} {
            width: 288px;
            margin-top: 0;
            padding: 16px;
            svg {
              height: 48px;
              width: 48px;
              }
            ${InnerContainer} {
              min-width: 256px;
              input, button {
                max-width: 256px;
              }
            }
            h2, h3 {
              font-size: 24px;
              line-height: 34px;
            }
            h3 {
              font-weight: 100;
            }
            h2 {
              font-weight: 600;
              margin-bottom: 12px;
            }

            label {
              font-weight: 500;
            }
          }
        p {
          margin: 18px auto 32px;
          max-width: 236px;
          text-align: left;
        }
    }
  `}
`;
