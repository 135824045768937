import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { DateRange } from 'api/system/utils/createDateRange';
import { getSolarEnergy } from 'api/system';
import { ChartDataType } from 'modules/types';
import { EnergyUnit } from 'api/types';
import Banner from 'shared/components/Banner/Banner';
import { LoadingSpinner } from 'shared/components/LoadingSpinner';
import { useTheme } from 'styled-components';
import { WarningIcon } from 'shared/components/icons';
import { useBreakpoints } from 'hooks/useWindowSize';
import { useTranslation } from 'react-i18next';
import { AnyChartDataProvider } from 'modules/system/utils/ChartDataProviders/AnyChartDataProvider';
import { BarChart } from './BarChart';
import { SpinnerContainer } from '../Chart.styles';

interface Props {
  dataProvider: AnyChartDataProvider;
  systemId: string;
  dateRange: DateRange;
}

export function EnergyChart({ dataProvider, systemId, dateRange }: Props) {
  const [chartData, setChartData] = useState<ChartDataType[] | null>(null);
  const [yAxisEnergyUnit, setYAxisEnergyUnit] = useState<EnergyUnit | null>(null);
  const [largestProductionValue, setLargestProductionValue] = useState<number | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { zoomLevel, startDate } = dateRange;

  const theme = useTheme();
  const screenSize = useBreakpoints();
  const { t } = useTranslation();

  const cacheStaleTime = 15 * 60 * 1000; // cache items are invalidated after 15 minutes, the rate at which ne360 refreshes telemetry

  const { data, isFetching, isError, error } = useQuery(
    ['solarEnergy', systemId, dateRange, startDate, zoomLevel, dataProvider],
    () => getSolarEnergy({ systemId, dateRange }).then((response) => response.data),
    {
      enabled: Boolean(dateRange) && Boolean(dataProvider),
      retry: false,
      staleTime: cacheStaleTime,
    },
  );

  const isChartDataEmpty = chartData && chartData.length === 0;

  useEffect(() => {
    if (!isFetching && data && dataProvider && Object.keys(data).length) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const [productionData, _largestProductionValue, _yAxisEnergyUnit] =
        dataProvider.prepareData<EnergyUnit>(data, dateRange);
      setYAxisEnergyUnit(_yAxisEnergyUnit as EnergyUnit);
      setLargestProductionValue(_largestProductionValue);
      setChartData(productionData);
    }
    // if the query completes and there's no data we shouldn't leave stale data in the displayed chart
    if (!isFetching && !data) {
      setChartData([]);
    }
  }, [startDate, zoomLevel, data, dataProvider, dateRange, isFetching]);

  useEffect(() => {
    // don't set errors if query is still loading
    if (!isFetching) {
      if ((isError && (error as AxiosError)?.response?.status === 400) || isChartDataEmpty) {
        setErrorMessage(t('system.monitoring.monitoringUnavailableDateRange'));
      } else if (isError) {
        setErrorMessage(t('system.monitoring.monitoringUnavailable'));
      }
    }
  }, [isError, error, t, isChartDataEmpty, isFetching]);

  return (
    <>
      {(isError || isChartDataEmpty) && errorMessage && (
        <>
          <br />
          <Banner
            bannerText={errorMessage}
            bgColor={theme.colors.bannerWarningYellow20}
            icon={<WarningIcon />}
            dismissable={false}
          />
        </>
      )}

      {!isError &&
        !isChartDataEmpty &&
        (yAxisEnergyUnit && largestProductionValue && chartData?.length && dataProvider ? (
          <BarChart
            yAxisUnit={yAxisEnergyUnit}
            domain={dataProvider.getDomain(dateRange, largestProductionValue)}
            dateRange={dateRange}
            data={chartData}
            axes={{
              x: dataProvider.getXAxis(dateRange, screenSize),
              y: dataProvider.getYAxis(largestProductionValue, yAxisEnergyUnit, screenSize),
            }}
            tooltipDateFormatter={dataProvider.tooltipDateFormatter}
            zoomLevel={zoomLevel}
          />
        ) : (
          <SpinnerContainer>
            <LoadingSpinner localCenter />
          </SpinnerContainer>
        ))}
    </>
  );
}

export default EnergyChart;
