import React from 'react';
import { EverBrightLogo } from 'shared/components/icons';
import { useTranslation } from 'react-i18next';
import { LINKS } from 'shared/links';
import { AuthBox, AuthWrapper, InnerContainer, LoginFooter } from '../../Auth.styles';
import { LoginForm } from '../../components/LoginForm/LoginForm';

export function Login() {
  const { t } = useTranslation();

  return (
    <AuthWrapper>
      <AuthBox>
        <EverBrightLogo />
        <InnerContainer>
          <h3>{t('loginScreen.headerOne')}</h3>
          <h2>{t('loginScreen.headerTwo')}</h2>
          <LoginForm />
        </InnerContainer>
      </AuthBox>
      <LoginFooter>
        {t('loginScreen.currentPartner')}{' '}
        <a href={LINKS.MYEVERBRIGHT_PARTNER_LOGIN}>{t('loginScreen.logInHere')}</a>
      </LoginFooter>
    </AuthWrapper>
  );
}

export default Login;
