import { AuthBox, InnerContainer } from 'modules/auth/Auth.styles';
import styled from 'styled-components';

export const CreatePasswordBox = styled(AuthBox)`
  ${({ theme }) => `
        h3 {
            margin-top: 20px;
        }

        h2 {
            margin-bottom: 12px;
        }

        div:first-of-type > span {
            margin-top: 8px;
        }

        ${InnerContainer} p {
            height: 20px;
        }

        @media screen and (max-width: ${theme.breakpoints.medium}) {
            width: 410px;

            h3, h2 {
                display: inline;
            }
            
            > svg {
                margin-bottom: 12px;
            }
            
            ${InnerContainer} {
                > div {
                    margin-top: 12px;
                 }
            }
        }

        @media screen and (max-width: ${theme.breakpoints.small}) {

            ${InnerContainer} {
                > div:first-of-type {
                    margin-top: 6px;
                 }
            }
        }
    `}
`;

export default CreatePasswordBox;
