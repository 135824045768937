import React, { useMemo } from 'react';
import { EnergySummaryType, PowerFlowDataResponse } from 'api/types';
import { useTranslation } from 'react-i18next';
import { MonitoringStatistic } from './MonitoringStatistic';
import { MonitoringSnapshotWrapper } from './MonitoringSnapshot.styles';

type Props = {
  energySummary: EnergySummaryType | null;
  powerFlow: PowerFlowDataResponse | null;
};

export function MonitoringSnapshot({ energySummary, powerFlow }: Props) {
  const { t } = useTranslation();

  const [energyToday, energyThisMonth, energyAllTime] = useMemo(() => {
    if (energySummary) {
      const { day_kwh: dayKwh, month_kwh: monthKwh, all_time_kwh: allTimeKwh } = energySummary;
      return [dayKwh, monthKwh, allTimeKwh];
    }
    return [null, null, null];
  }, [energySummary]);

  return (
    <MonitoringSnapshotWrapper>
      <MonitoringStatistic
        label={t('system.monitoring.currentPower')}
        value={powerFlow?.system?.power_kw || null}
        unit="kW"
      />
      <MonitoringStatistic label={t('system.monitoring.today')} value={energyToday} unit="kWh" />
      <MonitoringStatistic
        label={t('system.monitoring.thisMonth')}
        value={energyThisMonth}
        unit="kWh"
      />
      <MonitoringStatistic
        label={t('system.monitoring.allTime')}
        value={energyAllTime}
        unit="kWh"
      />
    </MonitoringSnapshotWrapper>
  );
}

export default MonitoringSnapshot;
