import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { resolvePath, useParams } from 'react-router';
import { format } from 'date-fns';
import { useQuery } from 'react-query';

import Header from 'shared/components/Header/Header';
import pages from 'pages';
import { DetailsCard } from 'modules/overview/components/DetailsCard/DetailsCard';
import Button from 'shared/components/Button/Button';
import { DownloadIcon } from 'shared/components/icons';
import { getAgreement, useDownloadContract } from 'api/agreement';
import { toast } from 'shared/components/Toast/Toast';
import { formatMoney } from 'shared/utils/formatMoney';
import { Details } from './AgreementDetails.styles';

export function AgreementDetails() {
  const { t } = useTranslation();
  const theme = useTheme();

  const { agreementId } = useParams();

  const { data: agreement } = useQuery(
    'agreement',
    () => getAgreement(agreementId!).then((response) => response.data),
    {
      enabled: Boolean(agreementId),
    },
  );

  const { mutate: download, isLoading } = useDownloadContract({
    onSuccess: (data) => {
      const { presigned_url: presignedUrl } = data;
      const link = document.createElement('a');
      link.href = presignedUrl;
      link.download = 'signed_everbright_contract.pdf';
      link.click();
      link.remove();
    },
    onError: () => {
      toast({
        type: 'error',
        title: t('toast.error'),
        message: t('projectQualification.somethingWentWrong'),
        theme,
      });
    },
  });

  const handleDownload = () => {
    if (agreement?.id) {
      download(agreement.id);
    } else {
      toast({
        type: 'error',
        title: t('toast.error'),
        message: t('projectQualification.somethingWentWrong'),
        theme,
      });
    }
  };

  const term =
    agreement?.contract_term === 1
      ? t('agreementDetails.termValue', { value: agreement?.contract_term })
      : t('agreementDetails.termValuePlural', { value: agreement?.contract_term });

  return (
    <div>
      <Header
        pageName="AGREEMENT"
        title={t('agreementDetails.agreementDetails')}
        routeBreadCrumbs={[
          {
            label: 'Overview',
            link: resolvePath(`/${pages.DASHBOARD}`),
          },
          { label: t('agreementDetails.agreementDetails') },
        ]}
      />

      {agreement ? (
        <Details>
          <DetailsCard
            header={`${agreement.product_display_name} #${agreement.homeowner_contract_id}`}
            data={[
              {
                label: t('agreementDetails.signed'),
                value: format(agreement.contract_signed_date, 'MMMM d, yyyy'),
                styleVariant: 'large',
              },
              {
                label: t('agreementDetails.term'),
                value: term,
                styleVariant: 'large',
              },
            ]}
            buttons={[
              <Button
                key={`${agreement.product_display_name} #${agreement.homeowner_contract_id}-edit-btn`}
                dataTestId={`${t('cta.edit')}-btn`}
                styleVariant="secondary"
                label={t('cta.download')}
                Icon={DownloadIcon}
                iconRight
                isLoading={isLoading}
                onClick={handleDownload}
              />,
            ]}
          />

          <DetailsCard
            header={t('agreementDetails.commencementCertificate')}
            data={[
              {
                label: t('agreementDetails.permissionToOperateDate'),
                value: agreement.pto_date ? format(agreement.pto_date, 'MMMM d, yyyy') : null,
                styleVariant: 'large',
              },
              {
                label: t('agreementDetails.inServiceDate'),
                value: agreement.in_service_date
                  ? format(agreement.in_service_date, 'MMMM d, yyyy')
                  : null,
                styleVariant: 'large',
              },
              {
                label: t('agreementDetails.contractCommencementDate'),
                value: agreement.commencement_date
                  ? format(agreement.commencement_date, 'MMMM d, yyyy')
                  : null,
                styleVariant: 'large',
              },
              {
                label: t('agreementDetails.firstPaymentDate'),
                value: agreement.first_payment_date
                  ? format(agreement.first_payment_date, 'MMMM d, yyyy')
                  : null,
                styleVariant: 'large',
              },
              {
                label:
                  agreement.reamortization_dates.length > 1
                    ? t('agreementDetails.reamortizationDatePlural')
                    : t('agreementDetails.reamortizationDate'),
                value: agreement.reamortization_dates.map((dates) => format(dates, 'MMMM d, yyyy')),
                styleVariant: 'large',
              },
              {
                label: t('agreementDetails.originalBalance'),
                value: agreement.amount_financed ? formatMoney(agreement.amount_financed) : null,
                styleVariant: 'large',
              },
              {
                label: t('agreementDetails.numberOfPayments'),
                value: agreement.number_of_payments.toString(),
                styleVariant: 'large',
              },
              {
                label: t('agreementDetails.monthlyPaymentCurrent'),
                value: agreement.amount_financed ? formatMoney(agreement.monthly_payment) : null,
                styleVariant: 'large',
              },
              {
                label: t('agreementDetails.contractRate'),
                value: Number.isNaN(agreement.interest_rate)
                  ? null
                  : `${Number(agreement.interest_rate).toFixed(2)}%`,
                styleVariant: 'large',
              },
              {
                label: t('agreementDetails.annualEscalator'),
                value: Number.isNaN(agreement.rate_escalator)
                  ? null
                  : `${agreement.rate_escalator}%`,
                styleVariant: 'large',
              },
            ]}
          />
        </Details>
      ) : null}
    </div>
  );
}

export default AgreementDetails;
