import React from 'react';
import { useTranslation } from 'react-i18next';

import { AccountType } from 'api/types';
import { Header } from 'shared/components/Header/Header';
import { OverHeaderWrapper } from './OverviewHeader.styles';
import { AddressSelect } from '../AddressSelect/AddressSelect';

type Props = {
  selectedAccount: AccountType;
  accounts: AccountType[];
  onAccountChange: (accountId: string) => void;
};

export function OverviewHeader({ selectedAccount, accounts, onAccountChange }: Props) {
  const { t } = useTranslation();

  const options = accounts.map((account) => ({ id: account.id, address: account.address }));

  return (
    <OverHeaderWrapper>
      <Header pageName="OVERVIEW" title={t('overview.welcomeBack')} />

      <AddressSelect
        selectedOption={{ id: selectedAccount.id, address: selectedAccount.address }}
        options={options}
        onSelectOption={onAccountChange}
      />
    </OverHeaderWrapper>
  );
}

export default OverviewHeader;
