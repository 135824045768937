import axios, { AxiosError } from 'axios';
import { useMutation } from 'react-query';

import { LoginRequestBody } from 'api/types';
import routes from 'routes';

export function login(data: LoginRequestBody) {
  return axios.post(routes.login, { ...data });
}

export function logout() {
  return axios.post(routes.logout, {});
}

export type ChangePasswordRequestBody = {
  old_password: string;
  new_password: string;
};

export function changePassword(body: ChangePasswordRequestBody) {
  return axios.post(routes.changePassword, { ...body });
}

type UseSendAccessCodeBody = {
  onSuccess?: () => void;
  onError?: (error: AxiosError) => void;
};

export const useSendAccessCode = ({ onSuccess, onError }: UseSendAccessCodeBody) =>
  useMutation((body: { email: string }) => axios.post(routes.sendAccessCode, body), {
    onSuccess,
    onError,
  });

export const useVerifyAccessCode = ({ onSuccess, onError }: UseSendAccessCodeBody) =>
  useMutation(
    (body: { email: string; access_code: string }) => axios.post(routes.verifyAccessCode, body),
    {
      onSuccess,
      onError,
    },
  );
