import React, { useEffect, useState } from 'react';
import { resolvePath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { pages } from 'pages';
import { toast } from 'shared/components/Toast/Toast';
import { Header } from 'modules/auth/components/Header/Header';
import {
  ClaimAccountForm,
  LoginFormInput,
} from 'modules/auth/components/ClaimAccountForm/ClaimAccountForm';
import { useCheckClaimToken, useClaimAccount } from 'api/accounts';
import { ClaimAccountLoading } from 'modules/auth/components/ClaimAccountLoading/ClaimAccountLoading';
import { AuthBox, AuthWrapper, LinkStyled, LoginFooter } from './ClaimAccount.styles';

export function ClaimAccount() {
  const { search } = useLocation();

  const [email, setEmail] = useState(new URLSearchParams(search).get('email'));

  const navigate = useNavigate();
  const { token } = useParams();
  const { t } = useTranslation();
  const theme = useTheme();

  const { mutate: checkToken } = useCheckClaimToken({
    onSuccess: (res) => {
      setEmail(res.email);
    },
    onError: (error) => {
      if (error.response?.status === 409) {
        toast({
          type: 'warning',
          title: t('toast.warning'),
          message: t('claimAccount.accountAlreadyExists'),
          theme,
        });
      } else if (error.response?.status === 400) {
        toast({
          type: 'error',
          title: t('toast.error'),
          message: t('claimAccount.invitationInvalid'),
          theme,
        });
        navigate(`/${pages.LOGIN}`, { replace: true });
      }
    },
  });

  useEffect(() => {
    if (token) {
      checkToken(token);
    } else {
      toast({
        type: 'error',
        title: t('toast.error'),
        message: t('claimAccount.invitationInvalid'),
        theme,
      });
      navigate(`/${pages.LOGIN}`, { replace: true });
    }
  }, [checkToken, navigate, t, theme, token]);

  const { mutate, isLoading } = useClaimAccount({
    onSuccess: () => {
      toast({
        type: 'success',
        title: t('toast.success'),
        message: 'Your account was successfully created.',
        theme,
      });

      navigate(`/${pages.LOGIN}`);
    },
  });

  const handleSubmitForm = ({ password }: LoginFormInput) => {
    if (token) {
      mutate({ token, password });
    }
  };

  return (
    <AuthWrapper>
      <AuthBox>
        <Header
          title={t('claimAccount.registerYour')}
          titleBold={t('claimAccount.account')}
          showLogo={!isLoading}
        />
        {isLoading ? <ClaimAccountLoading /> : null}

        {email && !isLoading ? (
          <ClaimAccountForm email={email} onSubmitForm={handleSubmitForm} />
        ) : null}
      </AuthBox>
      <LoginFooter>
        {t('claimAccount.alreadyHaveAnAccount')}{' '}
        <LinkStyled to={resolvePath(`/${pages.LOGIN}`)}>{t('loginScreen.logInHere')}</LinkStyled>
      </LoginFooter>
    </AuthWrapper>
  );
}

export default ClaimAccount;
