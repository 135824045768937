import React, { useState } from 'react';
import useUser from 'hooks/useUser';
import { useQuery } from 'react-query';

import { LoadingSpinner } from 'legacy/components/common/design-system';
import { getWelcomeChecklist } from 'api/agreement';
import { MilestoneType, WelcomeChecklistResult, WelcomeChecklistStatusType } from 'modules/types';
import { ErrorBoundary } from 'shared/components/ErrorBoundary/ErrorBoundary';
import { SystemDetails } from 'modules/earlyAccess/SystemDetails/SystemDetails';
import { SupportForm } from 'modules/support/components/SupportForm/SupportForm';
import { FlagName } from 'api/types';
import { Body, ProjectContainer, StatusContainer, SupportFormWrapper } from './Status.styles';
import { ProjectStatus } from '../components/ProjectStatus/ProjectStatus';
import { StatusAccordion } from '../components/StatusAccordion/StatusAccordion';
import { shouldLoadWelcomeChecklist } from '../utils/shouldLoadWelcomeChecklist';
import { WelcomeChecklist } from './WelcomeChecklist/WelcomeChecklist';
import { AutopayModal } from '../components/AutoPayModal/AutopayModal';
import { StatusHeader } from '../components/StatusHeader/StatusHeader';
import {
  perSessionAchDiscountModalM0,
  perSessionAchDiscountModalM2,
} from '../../../constants/localStorageKeys';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';

export function Status() {
  const [openChecklistModal, setOpenChecklistModal] = useState(false);
  const [openAutopayModal, setOpenAutopayModal] = useState(false);

  const { userResult, selectedAccount, setSelectedAccount, refetch: refetchUser } = useUser();
  const user = userResult?.user;

  const agreement =
    selectedAccount?.agreements.find(
      (_agreement) =>
        _agreement.welcome_checklists?.length === 0 &&
        _agreement.current_milestone === MilestoneType.CONTRACT_SIGNED,
    ) || selectedAccount?.agreements[0];

  const welcomeChecklists = agreement ? agreement.welcome_checklists : [];

  const hasCompletedWelcomeChecklist =
    welcomeChecklists.length > 0 &&
    welcomeChecklists[welcomeChecklists.length - 1].result === WelcomeChecklistResult.PASS;

  const { data: welcomeChecklistQuestions, isFetching: isFetchingWelcomeChecklist } = useQuery(
    ['welcomeChecklist', agreement, hasCompletedWelcomeChecklist],
    () => getWelcomeChecklist(agreement?.id!).then((res) => res.data),
    {
      enabled: agreement && shouldLoadWelcomeChecklist(agreement),
      refetchOnWindowFocus: false,
    },
  );

  const isLoading = !userResult || userResult.loading;

  const activeAccounts =
    userResult?.user?.accounts.filter((userAccount) => userAccount.agreements.length) || [];

  const isEarlyAccess = Boolean(activeAccounts?.length);

  const currentMilestone = agreement?.current_milestone || MilestoneType.IN_SERVICE;

  const showDWC = !hasCompletedWelcomeChecklist && agreement && welcomeChecklistQuestions;

  let modalKey: any;
  if (currentMilestone === MilestoneType.CONTRACT_SIGNED) modalKey = perSessionAchDiscountModalM0;
  if (currentMilestone === MilestoneType.INSTALLATION_COMPLETED)
    modalKey = perSessionAchDiscountModalM2;

  const {
    flags: { [FlagName.achModal]: achModalEnabled },
  } = useFeatureFlags();

  // should display the autopay modal for m0 and m2 agreements only
  const handleShowWelcomeModal = () => {
    if (
      modalKey &&
      (modalKey === perSessionAchDiscountModalM2 ||
        (agreement?.ach_discount_eligible && agreement.ach_elected))
    )
      setOpenAutopayModal(true);
  };

  const handleCloseWelcomeChecklist = async (welcomeChecklistResult?: WelcomeChecklistResult) => {
    await refetchUser();
    setOpenChecklistModal(false);

    if (welcomeChecklistResult === WelcomeChecklistResult.PASS) {
      handleShowWelcomeModal();
    }
  };

  const handleAccountChange = (accountId: string) => {
    if (accountId !== selectedAccount?.id) {
      const newAccount = user?.accounts.find((account) => account.id === accountId);
      setSelectedAccount(newAccount);
    }
  };

  return (
    <StatusContainer>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {user && selectedAccount && (
            <StatusHeader
              selectedAccount={selectedAccount}
              accounts={user.accounts}
              onAccountChange={handleAccountChange}
            />
          )}

          <Body>
            <ProjectContainer>
              <ProjectStatus
                currentMilestone={isEarlyAccess ? currentMilestone : 'CANCELED'}
                welcomeChecklistStatus={
                  agreement?.welcome_checklist_status || WelcomeChecklistStatusType.NOT_REQUIRED
                }
              />

              {isEarlyAccess ? (
                <StatusAccordion
                  currentMilestone={currentMilestone}
                  agreement={agreement}
                  isFetchingWelcomeChecklist={isFetchingWelcomeChecklist}
                  onOpenWelcomeChecklist={() => {
                    setOpenChecklistModal(true);
                  }}
                />
              ) : (
                <SupportFormWrapper>
                  <SupportForm email={userResult.user?.email || ''} />
                </SupportFormWrapper>
              )}
            </ProjectContainer>

            {agreement && isEarlyAccess && <SystemDetails agreement={agreement} />}
          </Body>
        </>
      )}
      {showDWC && (
        <ErrorBoundary>
          <WelcomeChecklist
            agreement={agreement}
            checklistQuestions={welcomeChecklistQuestions}
            isOpen={openChecklistModal}
            onCloseWelcomeChecklist={handleCloseWelcomeChecklist}
          />
        </ErrorBoundary>
      )}
      {achModalEnabled ? (
        <AutopayModal
          isOpen={openAutopayModal}
          modalKey={modalKey}
          onCloseAutopayModal={() => setOpenAutopayModal(false)}
        />
      ) : null}
    </StatusContainer>
  );
}

export default Status;
