import React from 'react';
import { Link, resolvePath, useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useUser } from 'hooks/useUser';
import { useBreakpoints } from 'hooks/useWindowSize';
import { SubmitHandler, useForm } from 'react-hook-form';
import { LoginReturnType } from 'contexts/UserContext';
import { toast } from 'shared/components/Toast/Toast';
import { pages } from 'pages';
import { EmailField } from 'shared/components/EmailField/EmailField';
import { PasswordField } from 'shared/components/PasswordField/PasswordField';
import { Button } from 'shared/components/Button/Button';
import { getDashboardRedirectPath } from 'modules/auth/Auth.utils';
import { LoginFormStyled } from './LoginForm.styles';

export interface LoginFormInput {
  email: string;
  password: string;
}

export function LoginForm() {
  const navigate = useNavigate();
  const theme = useTheme();
  const { login } = useUser();
  const { t } = useTranslation();
  const screenSize = useBreakpoints();

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    reset,
  } = useForm<LoginFormInput>({ mode: 'onSubmit' });

  const handleLoginSuccess = (data: LoginReturnType) => {
    const { hasDashboardAccess, redirectPath } = getDashboardRedirectPath(
      data.user,
      data.user?.accounts?.[0],
    );
    navigate(redirectPath);
    if (hasDashboardAccess) {
      navigate(resolvePath(pages.OVERVIEW, `/${pages.DASHBOARD}`));
    } else navigate(redirectPath);
  };

  const handleLoginError = () => {
    toast({
      type: 'error',
      title: 'Error',
      message: t('loginScreen.errors.AUTHENTICATION_FAILED'),
      theme,
    });
  };

  const { mutate } = useMutation({
    mutationFn: login,
    onSuccess: (data) => {
      if (data.error === true) {
        handleLoginError();
      } else {
        handleLoginSuccess(data);
      }
    },
    // unlikely to hit this since user provider handles errors from API call
    onError: () => {
      handleLoginError();
      reset();
    },
  });

  const onSubmit: SubmitHandler<LoginFormInput> = ({ email, password }) => {
    mutate({ email, password });
  };

  return (
    <LoginFormStyled onSubmit={handleSubmit(onSubmit)}>
      <EmailField
        register={register}
        onClearInput={resetField}
        hasError={Boolean(errors.email)}
        errorMessage={errors.email?.message}
        margin={`0 0 ${screenSize === 'sm' ? '16px' : '20px'}`}
      />
      <PasswordField
        register={register}
        onClearInput={resetField}
        hasError={Boolean(errors.password)}
        errorMessage={errors.password?.message}
        margin="0 0 12px"
      />
      <Link to={`/${pages.FORGOT_PASSWORD}`}>{t('loginScreen.forgotPassword')}</Link>
      <Button
        type="submit"
        label={t('loginScreen.login')}
        isFullWidth
        margin={`${screenSize === 'sm' ? '16px' : '32px'} 0 0`}
      />
    </LoginFormStyled>
  );
}

export default LoginForm;
