import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useTheme } from 'styled-components';
import { useBreakpoints } from 'hooks/useWindowSize';
import { useNavigate, useParams } from 'react-router';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';

import { Button } from 'shared/components/Button/Button';
import { BackButton } from 'modules/auth/components/BackButton';
import { AuthWrapper, InnerContainer } from 'modules/auth/Auth.styles';
import { EverBrightLogo } from 'shared/components/icons';
import { PasswordField } from 'shared/components/PasswordField/PasswordField';
import { toast } from 'shared/components/Toast/Toast';
import PasswordStrengthBar from 'react-password-strength-bar';

import {
  IFormInput,
  SHORT_SCORE_WORD,
} from 'modules/account/components/LoginDetailsForm/LoginDetailsForm';
import { MINIMUM_PASSWORD_LENGTH } from 'constants/auth';
import { submitCreatePassword } from 'api/accounts';
import { handlePasswordValidation } from 'modules/auth/Auth.utils';
import { pages } from 'pages';
import { SCORE_WORDS } from '../../../account/components/LoginDetailsForm/LoginDetailsForm';
import { CreatePasswordBox } from './CreatePassword.styles';

type CreatePasswordFormInput = Omit<IFormInput, 'oldPassword'>;

export function CreatePassword() {
  const [passwordScore, setPasswordScore] = useState(0);
  const [showScore, setShowScore] = useState(false);

  const { token } = useParams();
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const screenSize = useBreakpoints();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    resetField,
  } = useForm<CreatePasswordFormInput>({ mode: 'onSubmit' });

  const watchNewPassword = useWatch({
    control,
    name: 'newPassword',
  });

  const { mutate } = useMutation({
    mutationFn: submitCreatePassword,
    onSuccess: () => {
      toast({
        type: 'success',
        title: t('toast.success'),
        message: t('account.successfullyUpdated'),
        theme,
      });
      reset();
      navigate(`/${pages.LOGIN}`, { replace: true });
    },
    onError: () => {
      toast({
        type: 'error',
        title: t('toast.error'),
        message: t('account.unableToSave'),
        theme,
      });
    },
  });

  const onSubmit: SubmitHandler<CreatePasswordFormInput> = ({ newPassword }) => {
    mutate({ password: newPassword, token: token || '' });
  };

  return (
    <AuthWrapper>
      <BackButton />
      <CreatePasswordBox as="form" onSubmit={handleSubmit(onSubmit)}>
        <EverBrightLogo />
        <InnerContainer>
          <h3>{t('forgotPasswordScreen.enterYourNew')}</h3>
          <h2>
            {screenSize === 'md' || screenSize === 'sm' ? ' ' : ''}
            {t('forgotPasswordScreen.sendScreenHeaderTwo')}
          </h2>
          <div>
            <PasswordField
              register={register}
              onClearInput={resetField}
              hasError={Boolean(errors.newPassword)}
              errorMessage={errors.newPassword?.message}
              fieldName="newPassword"
              label={t('account.newPassword')}
              showPlaceholder={false}
              onValidate={(value) =>
                handlePasswordValidation({
                  value,
                  newPassword: watchNewPassword,
                  passwordScore,
                  translator: t,
                })
              }
            />
            <PasswordStrengthBar
              password={watchNewPassword}
              minLength={MINIMUM_PASSWORD_LENGTH}
              shortScoreWord={showScore ? SHORT_SCORE_WORD : ''}
              scoreWords={SCORE_WORDS}
              onChangeScore={(score) => {
                if (watchNewPassword?.length && !showScore) {
                  setShowScore(true);
                }
                if (!watchNewPassword?.length && showScore) {
                  setShowScore(false);
                }
                setPasswordScore(score);
              }}
            />
          </div>

          <PasswordField
            register={register}
            onClearInput={resetField}
            hasError={Boolean(errors.confirmPassword)}
            errorMessage={errors.confirmPassword?.message}
            fieldName="confirmPassword"
            label={t('forgotPasswordScreen.confirmPassword')}
            showPlaceholder={false}
            margin="16px 0 0"
            onValidate={(value) =>
              handlePasswordValidation({
                value,
                newPassword: watchNewPassword,
                passwordScore,
                translator: t,
                isConfirmPasswordField: true,
              })
            }
          />
          <Button
            type="submit"
            label={t('account.submitPassword')}
            isFullWidth
            margin={`${screenSize === 'sm' ? '18px' : '32px'} 0 0`}
          />
        </InnerContainer>
      </CreatePasswordBox>
    </AuthWrapper>
  );
}

export default CreatePassword;
