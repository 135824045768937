import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { resolvePath, useLocation, useNavigate } from 'react-router';
import { BackButton } from 'modules/auth/components/BackButton';
import { Button } from 'shared/components/Button/Button';
import { AuthBox } from 'modules/auth/Auth.styles';
import { EverBrightLogo } from 'shared/components/icons';
import { useMutation } from 'react-query';
import { sendResetPasswordLink } from 'api/accounts';
import { pages } from 'pages';
import handleFocusedKeyPress from 'shared/utils/handleFocusedKeypress';
import { CheckEmailInnerContainer, CheckEmailWrapper } from './CheckEmail.styles';

export function CheckEmail({ email: emailProp }: { email?: string }) {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const email = emailProp || location.state.email;

  const { mutate } = useMutation({
    mutationFn: sendResetPasswordLink,
  });

  const handleResendEmail = useCallback(() => mutate(email || ''), [mutate, email]);

  const goToResetPassword = useCallback(() => {
    navigate(resolvePath(pages.FORGOT_PASSWORD, '/'), { state: { email } });
  }, [email, navigate]);

  const handleKeyUpGoToReset = useCallback(
    (e: React.KeyboardEvent<HTMLElement>) => {
      handleFocusedKeyPress(e, goToResetPassword);
    },
    [goToResetPassword],
  );

  const handleKeyUpResendEmail = useCallback(
    (e: React.KeyboardEvent<HTMLElement>) => {
      handleFocusedKeyPress(e, handleResendEmail);
    },
    [handleResendEmail],
  );

  return (
    <CheckEmailWrapper>
      <BackButton />
      <AuthBox>
        <EverBrightLogo />
        <CheckEmailInnerContainer>
          <h3>{t('forgotPasswordScreen.sentScreenHeaderOne')}</h3>
          <h2> {t('forgotPasswordScreen.sentScreenHeaderTwo')}</h2>
          <p>{t('forgotPasswordScreen.sentScreenBodyOne')}</p>
          <p>{t('forgotPasswordScreen.sentScreenBodyTwo')}</p>
          <Button
            label={t('forgotPasswordScreen.resendEmail')}
            isFullWidth
            onClick={handleResendEmail}
            onKeyUp={handleKeyUpResendEmail}
          />
          <Button
            label={t('forgotPasswordScreen.reEnterEmailAddress')}
            styleVariant="tertiary"
            onClick={goToResetPassword}
            onKeyUp={handleKeyUpGoToReset}
            margin="0 auto"
          />
        </CheckEmailInnerContainer>
      </AuthBox>
    </CheckEmailWrapper>
  );
}

export default CheckEmail;
