import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { pages } from 'pages';
import { useSendAccessCode, useVerifyAccessCode } from 'api/account';
import { toast } from 'shared/components/Toast/Toast';
import { Header } from 'modules/auth/components/Header/Header';
import {
  VerificationPinForm,
  VerificationPinFormInput,
} from 'modules/auth/components/VerificationPinForm/VerificationPinForm';
import { AuthBox, AuthWrapper } from '../Registration/Registration.styles';

const VERIFICATION_TIME_SECONDS = 10 * 60; // 10 minutes

export function Verification() {
  const [searchParams] = useSearchParams();

  const [email] = useState(searchParams.get('email') || '');
  const [timeLeft, setTimeLeft] = useState(VERIFICATION_TIME_SECONDS);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    if (!email) {
      navigate(`/${pages.REGISTRATION}`);
    }
  }, [email, navigate]);

  useEffect(() => {
    let timerId: NodeJS.Timeout;

    if (timeLeft > 0) {
      timerId = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    }

    return () => clearInterval(timerId);
  }, [timeLeft]);

  const { mutate: sendAccessCode } = useSendAccessCode({
    onSuccess: () => {
      setTimeLeft(VERIFICATION_TIME_SECONDS);
    },
    onError: () => {
      toast({
        type: 'error',
        title: t('toast.error'),
        message: t('toast.somethingWentWrong'),
        theme,
      });
    },
  });

  const {
    mutate: verifyAccessCode,
    error: verifyAccessCodeError,
    isLoading,
  } = useVerifyAccessCode({
    onSuccess: () => {
      // TODO: Go To Create Password Screen
    },
    onError: ({ response }) => {
      const status = response?.status;
      const unCaughtError = status !== 400 && status !== 422 && status !== 429;

      if (status === 429) {
        navigate(`/${pages.TOO_MANY_ATTEMPTS}`);
      }

      if (unCaughtError) {
        toast({
          type: 'error',
          title: t('toast.error'),
          message: t('toast.somethingWentWrong'),
          theme,
        });
      }
    },
  });

  const handleSubmitPinForm = ({ pin }: VerificationPinFormInput) => {
    verifyAccessCode({ email, access_code: pin });
  };

  const handleResendPin = () => {
    sendAccessCode({ email });
  };

  let pinErrorMessage: string | undefined;
  if (verifyAccessCodeError?.response?.status === 400) {
    pinErrorMessage = t('verification.wrongCode');
  }

  if (verifyAccessCodeError?.response?.status === 422) {
    pinErrorMessage = t('verification.expiredCode');
  }

  return (
    <AuthWrapper>
      <AuthBox>
        <Header title={t('verification.checkYour')} titleBold={t('verification.email')} showLogo />
        <VerificationPinForm
          email={email}
          timeLeftSeconds={timeLeft}
          verifying={isLoading}
          pinError={pinErrorMessage}
          onSubmitForm={handleSubmitPinForm}
          onResendPin={handleResendPin}
        />
      </AuthBox>
    </AuthWrapper>
  );
}

export default Verification;
