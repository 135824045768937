import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PasswordStrengthBar from 'react-password-strength-bar';

import { MINIMUM_PASSWORD_LENGTH } from 'constants/auth';
import LINKS from 'shared/links';
import { useBreakpoints } from 'hooks/useWindowSize';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { LinkStyled } from 'shared/components/Header/Header.styles';
import { PasswordField } from 'shared/components/PasswordField/PasswordField';
import { Button } from 'shared/components/Button/Button';
import { Checkbox } from 'shared/components/Checkbox/Checkbox';
import { Text } from 'shared/components/Text/Text';
import { handlePasswordValidation } from 'modules/auth/Auth.utils';
import { ClaimAccountFormStyled, Label, Value } from './ClaimAccountForm.styles';

const SCORE_WORDS = ['Too Weak', 'Very Weak', 'Weak', 'Strong', 'Very Strong'];

export interface LoginFormInput {
  password: string;
  confirmPassword: string;
}

type Props = {
  email: string;
  onSubmitForm: SubmitHandler<LoginFormInput>;
};

export function ClaimAccountForm({ email, onSubmitForm }: Props) {
  const [passwordScore, setPasswordScore] = useState(0);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [termsError, setTermsError] = useState('');

  const { t } = useTranslation();
  const screenSize = useBreakpoints();

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    control,
  } = useForm<LoginFormInput>({ mode: 'onSubmit', reValidateMode: 'onSubmit' });

  const watchNewPassword = useWatch({
    control,
    name: 'password',
  });

  const onSubmit: SubmitHandler<LoginFormInput> = (formInput) => {
    if (!agreedToTerms) {
      setTermsError(t('claimAccount.youMustAgreeToPrivacyPolicy'));
      return;
    }

    onSubmitForm(formInput);
  };

  const handleChangeConfirmed = () => {
    setAgreedToTerms((c) => !c);
  };

  return (
    <ClaimAccountFormStyled onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Label as="p">{t('claimAccount.emailAddress')}</Label>
        <Value as="p" $margin="0 0 8px">
          {email}
        </Value>
      </div>

      <div>
        <PasswordField
          label={t('claimAccount.createPassword')}
          showPlaceHolder={false}
          register={register}
          onValidate={(value) =>
            handlePasswordValidation({
              value,
              newPassword: watchNewPassword,
              passwordScore,
              translator: t,
            })
          }
          onClearInput={resetField}
          hasError={Boolean(errors.password)}
          errorMessage={errors.password?.message}
          margin={`0 0 ${errors.password?.message ? '24px ' : 0}`}
        />

        <PasswordStrengthBar
          password={watchNewPassword}
          minLength={MINIMUM_PASSWORD_LENGTH}
          shortScoreWord=""
          scoreWords={SCORE_WORDS}
          onChangeScore={(score) => {
            setPasswordScore(score);
          }}
          className="password-strength-bar"
        />
      </div>

      <PasswordField
        fieldName="confirmPassword"
        label={t('claimAccount.confirmPassword')}
        showPlaceHolder={false}
        register={register}
        onValidate={(value) =>
          handlePasswordValidation({
            value,
            newPassword: watchNewPassword,
            passwordScore,
            translator: t,
            isConfirmPasswordField: true,
          })
        }
        onClearInput={resetField}
        hasError={Boolean(errors.confirmPassword)}
        errorMessage={errors.confirmPassword?.message}
        margin={`0 0 ${errors.confirmPassword?.message ? '24px' : '12px'}`}
      />

      <Checkbox
        isChecked={agreedToTerms}
        onChange={handleChangeConfirmed}
        margin="0 8px"
        error={!agreedToTerms}
        hint={termsError}
        text={
          <Text as="body">
            <Trans
              i18nKey="claimAccount.privacyPolicyConfirmation"
              components={{
                // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
                a: <LinkStyled to={LINKS.PRIVACY_POLICY} />,
              }}
            />
          </Text>
        }
      />
      <Button
        type="submit"
        label={t('claimAccount.registerAccount')}
        isFullWidth
        margin={`${screenSize === 'sm' ? '6px' : '12px'} 0 0`}
      />
    </ClaimAccountFormStyled>
  );
}

export default ClaimAccountForm;
