import React, { useCallback, useEffect, useMemo, useState } from 'react';
import axios, { AxiosError, AxiosResponse } from 'axios';
import pages from 'pages';
import { useNavigate, useLocation, matchRoutes } from 'react-router-dom';

const AxiosInterceptorContext = React.createContext({});

const routes = [
  { path: `/${pages.CLAIM_ACCOUNT}` },
  { path: `/${pages.FORGOT_PASSWORD}` },
  { path: `/${pages.RESET_PASSWORD}` },
  { path: `/${pages.CHAT}` },
  { path: `/${pages.REGISTRATION}` },
  { path: `/${pages.VERIFICATION}` },
  { path: `/${pages.TOO_MANY_ATTEMPTS}` },
];

interface Props {
  children: JSX.Element;
}

function AxiosInterceptorProvider({ children }: Props) {
  const [isReady, setIsReady] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const resInterceptor = useCallback((response: AxiosResponse) => response, []);

  const errInterceptor = useCallback(
    (error: AxiosError) => {
      const excluded = matchRoutes(routes, location);
      if (error.response?.status === 401 && !excluded?.length) {
        navigate(pages.LOGIN, { replace: true, state: { path: location.pathname } });
      }
      return Promise.reject(error);
    },
    [navigate, location],
  );

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(resInterceptor, errInterceptor);
    setIsReady(true);
    return () => axios.interceptors.response.eject(interceptor);
  }, [navigate, errInterceptor, resInterceptor]);

  const value = useMemo(() => ({}), []);

  return (
    <AxiosInterceptorContext.Provider value={value}>
      {isReady && children}
    </AxiosInterceptorContext.Provider>
  );
}

export { AxiosInterceptorProvider, AxiosInterceptorContext };
