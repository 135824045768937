import { BucketSize, DateRangeZoomLevel } from 'legacy/types';

export const getAggregationLevel = (zoomLevel: DateRangeZoomLevel): string | undefined => {
  switch (zoomLevel) {
    case DateRangeZoomLevel.DAY:
      return undefined;
    case DateRangeZoomLevel.WEEK:
      return BucketSize.DAY;
    case DateRangeZoomLevel.MONTH:
      return BucketSize.DAY;
    case DateRangeZoomLevel.YEAR:
      return BucketSize.MONTH;
    default:
      throw new Error(`Unsupported zoom level: ${zoomLevel}`);
  }
};

export default getAggregationLevel;
