import axios, { AxiosPromise } from 'axios';
import { UseMutationResult, useMutation } from 'react-query';

import routes from 'routes';
import {
  GetAgreementsRequestBody,
  UpdateAgreementRequestBody,
  WelcomeChecklistItemType,
  SubmitWelcomeChecklisRequestBody,
} from 'legacy/types';
import { AgreementType, Contract } from 'api/types';
import { WelcomeChecklistType } from 'modules/types';

export function getAgreement(agreementId: string): AxiosPromise<AgreementType> {
  return axios.get(routes.agreement(agreementId));
}

export function getAgreements(body?: GetAgreementsRequestBody) {
  return axios.get(routes.agreements, { params: body });
}

export function downloadContract(agreementId: string): AxiosPromise<Contract> {
  return axios.get(routes.contract(agreementId));
}

export function getWelcomeChecklist(agreementId: string): AxiosPromise<WelcomeChecklistItemType[]> {
  return axios.get(routes.welcomeChecklist(agreementId));
}

export function submitWelcomeChecklist({
  agreement,
  welcomeChecklist,
}: SubmitWelcomeChecklisRequestBody): AxiosPromise<WelcomeChecklistType> {
  return axios.post(routes.welcomeChecklist(agreement.id), {
    checklist: welcomeChecklist,
  });
}

export function updateAgreement(agreementId: string, body: UpdateAgreementRequestBody) {
  return axios.patch(routes.agreement(agreementId), body);
}
export function acknowledgeAuthCopy(agreementId: string) {
  return axios.patch(routes.authCopyAck(agreementId), {
    acknowledged: true,
  });
}

export function uploadAuthCopyAckCsv(file: File) {
  const formData = new FormData();
  formData.append('file', file);
  return axios.post(routes.uploadAuthCopyAckCsv, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
}

type UseDownloadContractBody = {
  onSuccess?: (data: Contract) => void;
  onError?: () => void;
};

export const useDownloadContract = ({
  onSuccess,
  onError,
}: UseDownloadContractBody): UseMutationResult<Contract, unknown, string> =>
  useMutation((id: string) => downloadContract(id).then((res) => res.data), { onSuccess, onError });
