import axios, { AxiosError } from 'axios';
import { UserFormType } from 'legacy/components/forms/UserForm';
import { UpdateAccountRequestBody } from 'legacy/types';
import { useMutation, UseMutationResult } from 'react-query';
import routes from 'routes';

export function updateAccount(account_id: string, body: UpdateAccountRequestBody) {
  return axios.patch(routes.account(account_id), { ...body });
}

export function checkClaimToken(token: string) {
  return axios.post(routes.claimToken, { token });
}

export function sendAdminClaimAccountEmail(user_id: string) {
  return axios.post(routes.sendClaimAccountEmail, { user_id });
}

export function sendHomeownerClaimAccountEmail(email: string, is_resend: boolean) {
  return axios.post(routes.sendClaimAccountEmail, { email, is_resend });
}

export function inviteHomeowner(user: UserFormType) {
  return axios.post(routes.invite, user);
}

export function inviteAdmin(user: UserFormType) {
  return axios.post(routes.invite, { ...user, is_admin_invite: true });
}

export function sendResetPasswordLink(email: string): Promise<undefined> {
  return axios.post(routes.forgotPassword, { email });
}

export function submitCreatePassword({
  password,
  token,
}: {
  password: string;
  token: string;
}): Promise<undefined> {
  return axios.post(routes.resetPassword, { token, password });
}

type UseCheckClaimTokenBody = {
  onSuccess?: (data: { email: string }) => void;
  onError?: (error: AxiosError) => void;
};

export const useCheckClaimToken = ({
  onSuccess,
  onError,
}: UseCheckClaimTokenBody): UseMutationResult<{ email: string }, unknown, string> =>
  useMutation((token: string) => checkClaimToken(token).then((res) => res.data), {
    onSuccess,
    onError,
  });

type UseClaimAccountBody = {
  onSuccess?: () => void;
  onError?: (error: AxiosError) => void;
};

export const useClaimAccount = ({
  onSuccess,
  onError,
}: UseClaimAccountBody): UseMutationResult<unknown, unknown, { token: string; password: string }> =>
  useMutation(
    (body: { token: string; password: string }) => axios.post(routes.claimUserAccount, body),
    {
      onSuccess,
      onError,
    },
  );
