import { AuthBox, AuthWrapper, InnerContainer } from 'modules/auth/Auth.styles';
import styled from 'styled-components';

export const CheckEmailInnerContainer = styled(InnerContainer)`
  ${({ theme }) => `

    h3, h2 {
        display: inline;
    }
    
    p {
        margin: 24px 0 0;
        font-weight: 300;
        color: ${theme.colors.textSecondaryGrey90};
        text-align: left;
        line-height: 24px;
        }
    button:last-of-type {
        height: 24px;
        font-weight: 700;
    }
    `}
`;

export const CheckEmailWrapper = styled(AuthWrapper)`
  ${({ theme }) => `
        ${AuthBox} {
        height: 500px;
            width: 500px;
            padding: 48px;
            margin-top: 15px;

            svg {
                margin-bottom: 20px;
            }
            
            button {
                margin-top: 32px;
            }

            @media screen and (max-width: ${theme.breakpoints.medium}) {
                width: 410px;
                height: 402px;
                padding: 24px 35px;

                ${CheckEmailInnerContainer} {
                    min-width: 339px;
                }

                h3, h2 {
                    line-height: 32px;
                }

                svg {
                    margin-bottom: 12px;
                }

                p {
                    margin-top: 16px;
                }
                
                button {
                    margin-top: 24px;
                }
            }

            @media screen and (max-width: ${theme.breakpoints.small}) {
                width: 288px;
                height: 422px;
                padding: 16px;

                ${CheckEmailInnerContainer} {
                    min-width: 256px;
                }

                p:first-of-type {
                    margin-top: 18px;
                }
                button {
                    margin-top: 20px;
                }
            }
        }
    `}
`;
