import React from 'react';
import { useTranslation } from 'react-i18next';

import { AccountType } from 'api/types';
import { Header } from 'shared/components/Header/Header';
import { AddressSelect } from 'modules/overview/components/AddressSelect/AddressSelect';
import { StatusHeaderWrapper } from './StatusHeader.styles';

type Props = {
  selectedAccount: AccountType;
  accounts: AccountType[];
  onAccountChange: (accountId: string) => void;
};

export function StatusHeader({ selectedAccount, accounts, onAccountChange }: Props) {
  const { t } = useTranslation();

  const options = accounts.map((account) => ({ id: account.id, address: account.address }));
  const showAddressSelect = options.length > 1;
  return (
    <StatusHeaderWrapper>
      <Header pageName="STATUS" title={t('earlyAccess.hello')} />

      {showAddressSelect && (
        <AddressSelect
          selectedOption={{ id: selectedAccount.id, address: selectedAccount.address }}
          options={options}
          onSelectOption={onAccountChange}
        />
      )}
    </StatusHeaderWrapper>
  );
}

export default StatusHeader;
