import React from 'react';
import { AuthBox } from 'modules/auth/Auth.styles';
import { EverBrightLogo } from 'shared/components/icons';
import { useTranslation } from 'react-i18next';
import { useBreakpoints } from 'hooks/useWindowSize';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router';
import { pages } from 'pages';
import { sendResetPasswordLink } from 'api/accounts';
import { EmailField } from 'shared/components/EmailField/EmailField';
import { Button } from 'shared/components/Button/Button';
import { BackButton } from 'modules/auth/components/BackButton';
import { resolvePath } from 'react-router-dom';
import { ResetPasswordInnerContainer, ResetAuthWrapper } from './ResetPassword.styles';
import { LoginFormInput } from '../../components/LoginForm/LoginForm';

export function ResetPassword() {
  const { t } = useTranslation();
  const screenSize = useBreakpoints();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
  } = useForm<Omit<LoginFormInput, 'password'>>({
    mode: 'onSubmit',
    defaultValues: {
      email: location.state?.email || '',
    },
  });

  const { mutate } = useMutation({
    mutationFn: sendResetPasswordLink,
  });

  const onSubmit: SubmitHandler<Omit<LoginFormInput, 'password'>> = ({ email }) => {
    mutate(email);
    navigate(resolvePath(pages.CHECK_EMAIL, '/'), { state: { email } });
  };

  return (
    <ResetAuthWrapper>
      <BackButton />
      <AuthBox as="form" onSubmit={handleSubmit(onSubmit)}>
        <EverBrightLogo />
        <ResetPasswordInnerContainer>
          <h3>{t('forgotPasswordScreen.sendScreenHeaderOne')}</h3>
          <h2> {t('forgotPasswordScreen.sendScreenHeaderTwo')}</h2>
          <p>{t('forgotPasswordScreen.sendScreenBodyOne')}</p>
          <p>{t('forgotPasswordScreen.sendScreenBodyTwo')}</p>
          <EmailField
            register={register}
            onClearInput={resetField}
            hasError={Boolean(errors.email)}
            errorMessage={errors.email?.message}
            margin="24px 0 0"
          />
          <Button
            type="submit"
            label={t('forgotPasswordScreen.resetPassword')}
            isFullWidth
            margin={`${screenSize === 'sm' ? '16px' : '32px'} 0 0`}
          />
        </ResetPasswordInnerContainer>
      </AuthBox>
    </ResetAuthWrapper>
  );
}

export default ResetPassword;
